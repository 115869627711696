export default Object.freeze({
    1: 'ADMINISTRATIVO',
    2: 'TI',
    3: 'MARKETING',
    4: 'DISTRIBUIÇÃO',
    5: 'PRODUTOS',
    6: 'COMERCIAL',
    7: 'CONTROLADORIA',
    8: 'DIRETORIA',
    9: 'FINANCEIRA',
    10: 'OPERAÇÕES VIA',
    11: 'OPERAÇÕES SOULPAY',
    12: 'RECURSOS HUMANOS',
    13: 'LEGAL',
});
