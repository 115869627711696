import methods from '@/enums/methods';
import Thoth from '../base/thoth';

const DEFAULT_INITIAL_DATE = '2023-01-31';
const DEFAULT_FINAL_DATE = '2023-12-31';

class ConciliationService extends Thoth {
    show({
        animaId, ofxId,
    }) {
        return this.fetch({
            url: 'conciliation',
            params: {
                animaId, ofxId,
            },
        });
    }

    create({ ofx: ofxId, anima: animaId }) {
        return this.fetch({
            url: 'handbook',
            data: {
                ofx: { id: ofxId },
                anima: { id: animaId },
            },
            method: methods.POST,
        });
    }

    showUncompleted() {
        return this.fetch({ url: 'file/uncompleted' });
    }

    showCompleted() {
        return this.fetch({ url: 'file/completed' });
    }

    complete({ animaId, ofxId }) {
        return this.fetch({
            method: methods.PUT,
            url: 'file/conciliation/completed',
            params: {
                animaId,
                ofxId,
            },
        });
    }

    check() {
        return this.fetch({
            url: '/file/check',
        });
    }

    checkFinishedFiles() {
        return this.fetch({
            url: '/file/check/finished',
        });
    }
}

export default new ConciliationService();
