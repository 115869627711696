<template>
  <b-modal
    id="modal-scrollable"
    size="lg"
    title="Arquivos"
    class="modal-scroll"
  >
    <validation-observer
      ref="simpleRules"
      #default="{ invalid }"
    >
      <b-row>
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Data Inicial"
            rules="required"
          >
            <b-form-select
              v-model="typeFile"
              class="mb-1"
              disabled
              :state="errors.length > 0 ? false : null"
              :options="[
                { value: 'anima', text: 'Anima' },
              ]"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Data Inicial"
            :rules="`required|${typeFile}`"
          >
            <b-form-file
              v-model="file"
              class="mb-2"
              :state="errors.length > 0 ? false : null"
              placeholder="Arraste ou selecione um arquivo"
              drop-placeholder="Arraste o arquivo aqui."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-button
        variant="primary"
        :disabled="invalid"
        @click="sendFile"
      >
        Enviar
      </b-button>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
    BFormFile, BButton, BRow, BCol, BFormSelect,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import '@validations';

import ConciliationAnimaService from '@/service/conciliation/csv';

export default {
    components: {
        BFormFile, BButton, BRow, BCol, ValidationProvider, ValidationObserver, BFormSelect,
    },

    data() {
        return {
            file: null,
            typeFile: 'anima',
        };
    },

    methods: {
        async sendFile() {
            this.callLoading(true);

            const { status } = await ConciliationAnimaService.import(this.file);

            const errorMessagesByStatus = {
                415: 'Arquivo sem transações',
                406: 'Arquivo já importado',
            };

            if (status === 200) {
                this.modalSuccess('Arquivo enviado com sucesso');
            } else {
                this.modalError(errorMessagesByStatus[status] || 'Erro ao importar arquivo');
            }

            this.callLoading(false);
        },
    },
};

</script>
