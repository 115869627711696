import methods from '@/enums/methods';
import Thoth from '@/service/base/thoth';

class ConciliationOfxService extends Thoth {
    show({
        id,
    }) {
        return this.fetch({
            url: 'ofx',
            params: {
                id,
            },
        });
    }

    async import(files) {
        const formData = new FormData();

        Object.entries(files).forEach(([key, value]) => formData.append(key, value));

        const { status } = await this.fetch({
            url: 'ofx',
            data: formData,
            method: methods.POST,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return status;
    }

    update({ id, ...data }) {
        return this.fetch({
            method: methods.PUT,
            url: 'file/update/infos',
            params: {
                id,
                type: 2,
            },
            data,
        });
    }

    deleteFile(fileId) {
        return this.fetch({
            method: methods.DELETE,
            url: 'file/delete/ofx',
            params: {
                id: fileId,
            },
        });
    }
}

export default new ConciliationOfxService();
