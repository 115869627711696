var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"modal-scroll",attrs:{"id":"modal-scrollable","size":"lg","title":"Arquivos"}},[_c('validation-observer',{ref:"simpleRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Data Inicial","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"mb-1",attrs:{"disabled":"","state":errors.length > 0 ? false : null,"options":[
              { value: 'anima', text: 'Anima' } ]},model:{value:(_vm.typeFile),callback:function ($$v) {_vm.typeFile=$$v},expression:"typeFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Data Inicial","rules":("required|" + _vm.typeFile)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-file',{staticClass:"mb-2",attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Arraste ou selecione um arquivo","drop-placeholder":"Arraste o arquivo aqui."},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-button',{attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.sendFile}},[_vm._v(" Enviar ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }