<template>
  <b-container
    v-if="bank"
    fluid
  >
    <ImportFile />

    <b-row>
      <b-col md="12">
        <b-form>
          <b-card>

            <Form

              :banks="bank"
              :data="request"
              :available-files="availableFiles"
              @send="getConciliation"
            />

          </b-card>
        </b-form>

      </b-col>

      <b-col
        v-if="conciliated.conciliations.length"
        md="12"
      >
        <Conciliation
          :can-remove="false"

          :data="conciliated.conciliations"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
    BRow,
    BCol,
    BContainer,
    BForm,
    BCard,
} from 'bootstrap-vue';

import '@validations';

import Conciliation from './components/Conciliation.vue';

import Form from './components/Form/Form.vue';
import ImportFile from './components/Form/ImportFile.vue';

import BankService from '@/service/bank/';

import ConciliationService from '@/service/conciliation';

export default {
    components: {
        BCard,
        BContainer,
        BRow,
        BCol,
        BForm,
        Conciliation,
        ImportFile,
        Form,
    },

    data() {
        return {
            request: {
                bank: null,
                account: null,
            },

            anima: { data: [] },
            ofx: { data: [] },
            conciliated: { conciliations: [] },
            availableFiles: {},
            bank: null,
        };
    },

    async mounted() {
        await this.getAvailableFiles();
        const { data } = await BankService.index();

        this.bank = data;

        if (this.$route.query?.animaId && this.$route.query?.ofxId) {
            const account = this.availableFiles.anima.find(item => item.anima_reports).anima_reports.bank_accounts.current_account;
            const bank = data.find(item => item.currentAccount === account);

            this.request = {
                bank: bank.bank,
                account,
                animaFileId: +this.$route.query.animaId,
                ofxFileId: +this.$route.query.ofxId,
            };

            await this.getConciliation();
        }
    },

    methods: {
        async getAvailableFiles() {
            const { data } = await ConciliationService.checkFinishedFiles();

            const { anima, ofx } = this.groupBy(data.map(item => ({ ...item, table: item.table.toLowerCase() })), 'table');

            this.availableFiles = { anima, ofx };
        },
        async getConciliation() {
            this.callLoading(true);
            const { data, status } = await ConciliationService.show({
                animaId: this.request.animaFileId,
                ofxId: this.request.ofxFileId,
            });
            this.callLoading(false);

            if (status === 200) {
                data.data.conciliations = data.data.conciliations.map(({ conciliated, ...item }) => ({
                    ...item,
                    conciliated: !!conciliated,
                }));
                this.conciliated = data.data;
            } else {
                this.error('Não foi possível encontrar dados com esses paramêtros nas tabelas');
            }
            return { data, status };
        },
    },
};
</script>

  <style lang="scss">
  .conciliate-button {
    min-width: 14rem;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  #modal-scrollable {
    .modal-footer {
      display: none;
    }
  }
  </style>
