<template>
  <ConciliationTable
    title="Conciliados"
    :fields="fields"
    :items="data"
    :disabled-values="true"
    :can-edit="false"
    @removeItem="removeRow"
  />
</template>

<script>
import ConciliationTable from './Table/Table.vue';

import ConciliationAnimaService from '@/service/conciliation/csv';

const defaultFields = [
    { key: 'conciliated', label: '' },
    { key: 'date', label: 'Lançamento' },
    { key: 'name', label: 'Cliente' },
    { key: 'description', label: 'Descrição' },
    { key: 'value', label: 'Valor' },
];

export default {
    components: {
        ConciliationTable,
    },

    props: {
        data: {
            type: Array,
            default: () => [],
        },

        canRemove: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            fields: defaultFields,

        };
    },

    mounted() {
        if (!this.conciliationIsFinished() && this.canRemove) { this.fields.push({ key: 'back', label: '' }); }
    },

    methods: {
        async removeRow(id) {
            await ConciliationAnimaService.delete(id);
            this.$emit('removed-item');
        },

        conciliationIsFinished() {
            return this.data.some(item => item.is_completed);
        },
    },
};
</script>
