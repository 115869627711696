import generateAccountName from '@/views/pages/Conciliation/utils/generate-account-name';

export default {
    methods: {
        doAccountOptions(data, bank) {
            const defaultOption = {
                value: null, text: 'Selecione Uma Conta',
            };

            if (!bank) {
                return [defaultOption];
            }

            const options = data[bank]
                .map(({ branchCode, currentAccount, affiliation }) => ({ value: currentAccount, text: generateAccountName(currentAccount, branchCode, affiliation) }))

                .sort((firstItem, secondItem) => firstItem.text.localeCompare(secondItem.text));

            options.unshift(defaultOption);

            return options;
        },
    },
};
