<template>
  <b-card>
    <b-modal
      ref="commentModal"
      size="lg"
      hide-footer
    >
      <comment
        :data="selectedLine"
        @finish="() => {
          $refs.commentModal.hide()
          this.$emit('finish-update')
        }"
      />
    </b-modal>
    <b-row class="px-2 justify-content-between align-items-center">
      <h4
        v-if="title"
        class="mb-1"
      >
        {{ title }}
      </h4>

      <div>
        <slot name="summary" />
      </div>

    </b-row>
    <b-form-group
      label="Filtro"
      label-for="filter"
    >
      <b-form-input
        v-model="filter"
        placeholder="Filtro"
      />

    </b-form-group>

    <b-table
      responsive
      :total-rows="totalRows"
      class="text-center conciliated-table"
      :fields="fields"
      :items="items"
      :hover="canEdit"
      :filter="filter"
      @filtered="onFiltered"
      @row-clicked="handleClickTable"
    >
      <template v-slot:head(confirm)>
        <b-form-checkbox
          v-model="allCheck"
        />
      </template>

      <template #cell(confirm)="data">
        <div class="d-flex justify-content-center">

          <b-form-checkbox
            v-model="data.item.confirm"
            checked="true"
          />
        </div>
      </template>

      <template #cell(lanc_value)="data">
        <span :class="data.item.lanc_value < 0 ? 'danger' : 'success'"> {{ +data.item.lanc_value | value }} </span>
      </template>

      <template #cell(value)="data">
        <span
          v-if="data.item.value"
          :class="data.item.value < 0 ? 'danger' : 'success'"
        > {{ +data.item.value | value }} </span>
      </template>

      <template #cell(date)="data">
        {{ data.item.date | date }}
      </template>

      <template #cell(lanc_date)="data">
        {{ data.item.lanc_date | date }}
      </template>

      <template #cell(name)="data">
        <span
          v-if="data.item.name"
          v-b-tooltip.hover.top="data.item.name"
        > {{ data.item.name.length > 15 ? `${data.item.name.substr(0, 14)}...` : data.item.name }} </span>
        <span v-else> -------- </span>
      </template>

      <template #cell(description)="data">
        <span v-b-tooltip.hover.top="data.item.description"> {{ data.item.description && data.item.description.length > 23 ? `${data.item.description.substr(0, 23)}...` : data.item.description ? data.item.description : '------' }} </span>
      </template>

      <template #cell(complement)="data">
        <span v-b-tooltip.hover.top="data.item.complement"> {{ data.item.complement && data.item.complement.length > 12 ? `${data.item.complement.substr(0, 12)}...` : data.item.complement ? data.item.complement : '------' }} </span>
      </template>

      <template #cell(event_description)="data">
        <span v-b-tooltip.hover.top="data.item.event_description"> {{ data.item.event_description && data.item.event_description.length > 15 ? `${data.item.event_description.substr(0, 15)}...` : data.item.event_description ? data.item.event_description : '------' }} </span>

      </template>

      <template #cell(back)="data">
        <b-button
          variant="danger"
          @click="handleClickRemoveButton(data)"
        >
          Remover
        </b-button>
      </template>

      <template #cell(conciliated)="data">
        <div class="d-flex justify-content-center">
          <b-form-checkbox
            v-model="data.item.conciliated"
            :class="data.item.conciliated_type && 'custom-control-success'"
            disabled
          />
        </div>
      </template>

    </b-table>
    <div>
      <slot name="footer" />
    </div>

  </b-card>
</template>

<script>
import {
    BCard, BTable, BFormCheckbox, VBTooltip, BFormInput, BFormGroup, BButton, BRow, BModal,
} from 'bootstrap-vue';

import Comment from './Comment.vue';

export default {
    components: {
        BCard,
        BTable,
        BFormCheckbox,
        BFormInput,
        BFormGroup,
        BButton,
        Comment,
        BRow,
        BModal,
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    props: {
        title: {
            type: String,
            default: () => '',
        },

        fields: {
            type: Array,
            default: () => [],
        },

        items: {
            type: Array,
            default: () => [],
        },

        canRemove: {
            type: Boolean,
            default: () => true,
        },

        canEdit: {
            type: Boolean,
            default: () => true,
        },
    },

    data() {
        return ({
            filter: '',
            allCheck: false,
            selectedLine: {},
        });
    },

    computed: {
        totalSelected() {
            return this.items.reduce((acc, item) => {
                if (item.confirm) {
                    return acc + +item.value || +item.lanc_value;
                }

                return acc;
            }, 0);
        },

        totalRows() {
            return this.items.length;
        },
    },

    watch: {
        allCheck(value) {
            this.$emit('check-all', value);
        },
    },

    methods: {
        handleClickTable(line) {
            if (this.canEdit) {
                this.selectedLine = line;
                this.$refs.commentModal.show();
            }
        },

        onFiltered(tableItems) {
            this.totalRows = tableItems.length;
        },

        handleClickRemoveButton({ item }) {
            this.$emit('removeItem', item.id);
        },
    },
};
</script>

<style scoped>

.danger { color: #ea5455 }
.success { color: #28c76f }

  .conciliated-table {
    max-height: 700px;
    }

    .conciliated-table, .conciliated-table .table thead th {
        font-size: 12px !important;
    }
</style>
