<template>
  <b-form>
    <b-row>

      <b-col md="6">
        <b-form-group
          label="Comentário"
          label-for="comment"
        >
          <BFormInput
            id="comment"
            v-model="data.comment"
            placeholder="Comentário"
          />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label="Departamento"
          label-for="department"
        >
          <BFormSelect
            id="department"
            v-model="data.department"
            :options="departmentOptions"
            placeholder="Departamento"
          />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label="Origem"
          label-for="source"
        >
          <BFormInput
            id="source"
            v-model="data.source"
            placeholder="Origem"
          />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label="Destino"
          label-for="target"
        >
          <BFormInput
            id="target"
            v-model="data.target"
            placeholder="Destino"
          />
        </b-form-group>
      </b-col>

      <b-col
        md="12"
      >
        <b-button
          variant="primary"
          @click="$emit('click-save-button')"
        >
          Salvar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
    BForm, BFormInput, BCol, BButton, BFormGroup, BFormSelect, BRow,
} from 'bootstrap-vue';

import departments from '@/enums/departments';

export default {
    components: {
        BForm,
        BFormInput,
        BFormSelect,
        BCol,
        BButton,
        BFormGroup,
        BRow,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            departmentOptions: [
                { value: null, text: 'Selecione um Departamento' },
                ...Object.entries(departments).map(([key, item]) => ({
                    value: key,
                    text: item,
                })).sort((a, b) => a.text.localeCompare(b.text)),
            ],
        };
    },

    created() {
        this.data.department = this.data.department || null;
    },
};
</script>
