export default {
    methods: {
        doBankOptions(data) {
            const defaultOption = { value: null, text: 'Selecione Um Banco' };

            return [
                defaultOption,
                ...Object.keys(data).map(item => (item)),
            ];
        },
    },
};
