import Thoth from '@/service/base/thoth';
import methods from '@/enums/methods';

class ConciliationAnimaService extends Thoth {
    auto({
        animaId, ofxId,
    }) {
        return this.fetch({
            params: {
                animaId, ofxId,
            },
            url: 'auto',
            method: methods.POST,
        });
    }

    show({ id }) {
        return this.fetch({
            url: 'anima',
            params: {
                id,
            },
        });
    }

    async import(file) {
        const formData = new FormData();
        formData.append('AnimaFile', file);

        const { status } = await this.fetch({
            url: 'anima',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return status;
    }

    delete(id) {
        return this.fetch({
            method: methods.DELETE,
            url: 'removeRow',
            data: {
                id,
            },
        });
    }

    update({ id, ...data }) {
        return this.fetch({
            method: methods.PUT,
            url: 'file/update/infos',
            params: {
                id,
                type: 1,
            },
            data,
        });
    }

    deleteFile(fileId) {
        return this.fetch({
            method: methods.DELETE,
            url: 'file/delete/anima',
            params: {
                id: fileId,
            },
        });
    }
}

export default new ConciliationAnimaService();
