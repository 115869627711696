<template>
  <b-form>
    <validation-observer
      #default="{ invalid }"
    >

      <b-row class="mb-1">

        <b-col md="2">
          <b-form-group
            label="Banco*"
            label-for="bank"
          >
            <validation-provider
              #default="{ errors }"
              name="Banco"
              rules="required"
            >
              <b-form-select
                id="bank"
                v-model="data.bank"
                :state="errors.length > 0 ? false : null"
                :options="bankOptions"
              />
              <small class="text-danger">{{ errors[0] }} </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group
            label="Conta*"
            label-for="account"
          >
            <validation-provider
              #default="{ errors }"
              name="Conta"
              rules="required"
            >
              <b-form-select
                id="account"
                v-model="data.account"
                :state="errors.length > 0 ? false : null"
                :options="accountOptions"
              />
              <small class="text-danger">{{ errors[0] }} </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            label="Arquivo Anima*"
            label-for="account"
          >
            <validation-provider
              #default="{ errors }"
              name="Arquivo Anima"
              rules="required"
            >
              <b-form-select
                id="account"
                :disabled="disabled"
                v-model="data.animaFileId"
                :state="errors.length > 0 ? false : null"
                :options="animaAvailableFilesOptions"
              />
              <small class="text-danger">{{ errors[0] }} </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            label="Arquivo Ofx*"
            label-for="account"
          >
            <validation-provider
              #default="{ errors }"
              name="Arquivo Ofx"
              rules="required"
            >
              <b-form-select
                id="account"
                :disabled="disabled"
                v-model="data.ofxFileId"
                :state="errors.length > 0 ? false : null"
                :options="ofxAvailableFilesOptions"
              />
              <small class="text-danger">{{ errors[0] }} </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="12">

          <b-button
            variant="primary"
            class="d-block mx-auto"
            :disabled="invalid"
            @click="$emit('send')"
          >
            Carregar Dados
          </b-button>
        </b-col>
      </b-row>

    </validation-observer>
  </b-form>
</template>

<script>
import {
    BForm,
    BFormSelect,
    BFormGroup,
    BRow,
    BCol,
    BButton,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import GetBankMixin from '@/mixins/banks/GetBanks';
import AccountBankMixin from '@/mixins/banks/AccountOptions';
import BankMixin from '@/mixins/banks/BankOptions';

import formatDate from '@/utils/date'

export default {
    components: {
        BForm,
        BFormGroup,
        BFormSelect,
        BRow,
        BButton,
        ValidationProvider,
        BCol,
        ValidationObserver,
    },

    mixins: [BankMixin, GetBankMixin, AccountBankMixin],

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        disabled: {
            type: Boolean,
            default: () => false
        },

        availableFiles: {
            required: true,
            type: Object,
        },

        banks: {
            required: true,
            type: Array,
        }
    },

    data() {
        return {
            bankAccounts: {},

        };
    },

    computed: {
        accountOptions() {
            return this.doAccountOptions(this.bankAccounts, this.data.bank);
        },

        bankOptions() {
            return this.doBankOptions(this.bankAccounts);
        },

        animaAvailableFilesOptions() {
           return this.doFileOptions(this.availableFiles.anima)
        },

        ofxAvailableFilesOptions() {
            return this.doFileOptions(this.availableFiles.ofx)
        },
    },

    watch: {
        'data.bank': function handleBankChange() {
            this.data.account = null;
        },

        'data.account': function() {
            const getFirstData = (data) => data.reduce((acc, item) => item.file_date > acc.file_date ? acc : item, {})
            const filterByAccount = data => data.filter(this.filterFileByAccount)
             
            const { id: minusAnima } = getFirstData(filterByAccount(this.availableFiles.anima))
            const { id: minusOfx } = getFirstData(filterByAccount(this.availableFiles.ofx))

            this.data.animaFileId = minusAnima
            this.data.ofxFileId = minusOfx
        },

    },

    async mounted() {
        this.callLoading(true);
        this.bankAccounts = this.getBankData(this.banks);
        this.callLoading(false);
    },

    methods: {
        filterFileByAccount(item) {
            if(!item.header_ofxes && !item.anima_reports) return false

            const { bank_accounts: bankAccounts } = item.header_ofxes || item.anima_reports

            return bankAccounts.current_account === (this.data.account || '')
        },

        doFileOptions(data) {

            return data?.filter(this.filterFileByAccount).sort((a, b) => a.file_date > b.file_date).map(item => ({
                    value: item.id,
                    text: `${item.file_name} Lançado: ${formatDate(item.file_date)}`
            })) || []
        }
    },

};
</script>
