<template>
  <b-form>
    <p>
      Lançamento: {{ data.lanc_date || data.date | date }}
    </p>

    <p>
      Descrição: {{ data.description || data.complement || '-------' }}
    </p>

    <p v-if="data.event_description">
      Descrição do evento: {{ data.event_description }}
    </p>

    <p> Valor: {{ data.value || data.lanc_value | value }}</p>

    <update-form
      :data="data"
      @click-save-button="addComment"
    />

  </b-form>
</template>

<script>
import {
    BForm, BRow,
} from 'bootstrap-vue';

import ConciliationAnimaService from '@/service/conciliation/csv';
import ConciliationOfxService from '@/service/conciliation/ofx';

import UpdateForm from '../Form/Update.vue';

import departments from '@/enums/departments';

const servicesByTypeId = {
    1: ConciliationAnimaService,
    2: ConciliationOfxService,
};

export default {
    components: {
        BForm,
        BRow,
        UpdateForm,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            departmentOptions: [
                { value: null, text: 'Selecione um Departamento' },
                ...Object.entries(departments).map(([key, item]) => ({
                    value: key,
                    text: item,
                })).sort((a, b) => a.text.localeCompare(b.text)),
            ],
        };
    },

    created() {
        this.data.department = this.data.department || null;
    },

    methods: {
        async addComment() {
            this.callLoading(true);
            const { status } = await servicesByTypeId[this.data.type_id].update({
                id: this.data.id,
                source: this.data.source,
                target: this.data.target,
                comment: this.data.comment,
                department: this.data.department,
            });

            this.callLoading(false);

            if (status === 200) this.modalSuccess('Alterado com sucesso');
            else this.modalError('Erro ao atualizar');

            this.$emit('finish');
        },
    },
};
</script>
