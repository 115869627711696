export default {
    methods: {
        getBankData(data) {
            return data.reduce((acc, item) => {
                acc[item.bank] = acc[item.bank] || [];

                acc[item.bank].push(item);

                return acc;
            }, {});
        },
    },
};
